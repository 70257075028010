.news-card{
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 0!important;
      transition: box-shadow 0.7s ease;
      height: 100%;
      border-radius: 5px!important;
      overflow: hidden;
      .img{
        overflow: hidden;
        // height: 50%;
        img{
          width: 100%;
          height: 100%;
          transition: all 1s ease;
        }
      }
      &:hover{
        box-shadow: 10px 16px 50px -9px #e2e2e2;
        img{  
            transform: scale(1.15);
        }
      }
      .link{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        cursor: pointer;
      }
      .content{
      display: flex;
      flex-direction: column;
      gap:10px;
      padding: 20px;
      .readmore{ align-items: center;}
      }
    .desc{
      height: (27*3)px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical
    }
}
.landscape{
  position: relative;
  .link{
    position: absolute;
    width: 100%;
    height: 100%;
  }
  border: none!important;
  padding: 0!important;
  .desc{ display: none;}
  .img{ 
    width: 30%;
    img{ width: 100%;}
    }
  .content{ padding-left: 15px; p{font-size: 16px; line-height: 24px;}}
  .readmore{ display: none!important;}
  margin-bottom: 30px;
  // @media(max-width: 1400px){
  //   .img{ width: 100%;}
  // }
}